import React from 'react';
import { Layout } from 'components/layout/layout';
import { useLocation } from '@reach/router';
import 'styles/default.scss';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { Seo } from 'components/seo';

// 開発時しか表示しない
const allowDomains = [
  'pansuku-app-dev-bakery-gift.web.app',
  'panforyou-media-dev-ticket.web.app',
  'bakery-gift-map-update.web.app',
  'localhost:8000',
  'localhost:9000',
];
const contentfulUrl = 'https://app.contentful.com/spaces/2747ut8fg6rs/entries/';
const isBrowser = typeof window !== 'undefined';

const PreviewShopsPage = () => {
  const { host } = useLocation();
  const data = useStaticQuery<GatsbyTypes.PreviewShopsQuery>(graphql`
    query PreviewShops {
      count: allContentfulSellerLocation {
        totalCount
      }
      allContentfulSellerLocation(
        filter: { node_locale: { eq: "ja-JP" } }
        sort: { fields: contentful_id, order: ASC }
      ) {
        edges {
          node {
            group {
              name
            }
            id: contentful_id
            name
            postalCode
            prefecture
            city
            address1
            address2
            locationText
            location {
              lon
              lat
            }
            gifteeStoreId
          }
        }
      }
    }
  `);
  if (!allowDomains.includes(host) && isBrowser) {
    window.location.href = '/404.html';

    return null;
  }
  const rows = data.allContentfulSellerLocation.edges.map((e, index) => {
    const { id, name, locationText } = e.node;

    return (
      <tr
        key={id}
        style={{ backgroundColor: index % 2 === 0 ? '#cccc' : '#eeee' }}
      >
        <td>{id}</td>
        <td>{name}</td>
        <td>
          <Link
            to={`https://www.google.co.jp/maps/search/${locationText}`}
            target="_blank"
          >
            {locationText}
          </Link>
        </td>
        <td>
          <Link to={`${contentfulUrl}${id}`} target="_blank">
            contentful link
          </Link>
        </td>
      </tr>
    );
  });

  return (
    <Layout>
      <Seo title="shoplist" />
      <main>
        <h1>shoplist</h1>
        <table>
          <thead>
            <tr>
              <th style={{ width: '110px' }}>ID</th>
              <th style={{ fontSize: '14px' }}>Name</th>
              <th>locationText検証link</th>
              <th style={{ width: '130px' }}>contentful link</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </main>
    </Layout>
  );
};

export default PreviewShopsPage;
